import styled from "styled-components";
import {
  BP, Colors, Font, Rem, 
} from "../../../../commons/Theme";
import { StyledParagraph } from "../../../../commons/Typography";
import { motion } from "framer-motion";
import { CssLi } from "../../../globalstyle";

const getTextColor = (isDarkBackground) =>
  isDarkBackground ? Colors.white : Colors.black;

const getBackgroundColor = (isDarkBackground) =>
  isDarkBackground ? Colors.black : Colors.white;

export const StyledItemContainer = styled(motion.div)`
  color: ${({ isDarkBackground }) => getTextColor(isDarkBackground)};
  @media (${BP.ipad}) {
    max-width: 100%;
  }

  img {
    width: 100%;
  }

  & > * {
    margin-bottom: ${Rem(35)};
  }

  & > button {
    width: 100%;

    @media (${BP.ipad}) {
      width: ${Rem(320)};
    }
  }
`;

export const StyledItemTitle = styled(motion.h3)`
  font-family: ${Font.sans};
  font-weight: 700;
  font-size: ${Rem(32)};
  line-height: ${Rem(40)};
`;

export const StyledItemDescription = styled(StyledParagraph)`
  padding: 0;

  font-family: ${Font.sans};
  font-weight: 100;

  li {
    ${CssLi}
  }
  @media (${BP.ipad}) {
    min-height: ${({ isCarousel }) => isCarousel === true ? "300px" : "auto"};
  }
`;

export const StyledLineGridLeft = styled(motion.div)`
  display: none;
  position: absolute;
  top: 0;
  right: ${Rem(-64)};
  width: ${Rem(60)};
  height: 2px;
  background-color: ${Colors.yellow};
  @media (${BP.tablet}) {
    display: block;
  }
`;

export const StyledLineGridRight = styled(motion.div)`
  display: none;
  position: absolute;
  top: 0;
  left: ${Rem(-64)};
  width: ${Rem(60)};
  height: 2px;
  background-color: ${Colors.yellow};
  @media (${BP.tablet}) {
    display: block;
  }
`;
