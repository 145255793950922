import React, { useContext, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import Swiper, { Pagination } from "swiper";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { fadeGridImgFromLeft } from "../animations";
import { StyledSwiperBullets } from "../../ImagesCarousel/style";
import { DataContext } from "../../../contexts/DataContext";
import {
  StyledImageCarouselGridItemContainer,
  StyledImagesCarouselGridItemWrapper,
} from "./style";

const ImagesCarouselImage = ({ image }) => {
  const { pageImages } = useContext(DataContext);
  const imageData = pageImages.find((e) => e.path === image);

  return (
    <StyledImageCarouselGridItemContainer className="swiper-slide">
      {imageData && (
        <div>
          <GatsbyImage
            image={getImage(imageData)}
            alt={imageData.alt}
            style={{ position: "unset" }}
          />
        </div>
      )}
    </StyledImageCarouselGridItemContainer>
  );
};

Swiper.use([Pagination]);

export default ({ images, onSlideChange }) => {
  const sliderEl = useRef();
  const sliderPaginationEl = useRef();

  useEffect(() => {
    new Swiper(sliderEl.current, {
      allowTouchMove: true,
      slidesPerView: "auto",
      spaceBetween: 50,
      observer: true,
      observeParents: true,
      pagination: {
        el: sliderPaginationEl.current,
        clickable: true,
      },
      on: {
        activeIndexChange: onSlideChange,
      },
    });
  }, [sliderEl]);

  return (
    <StyledImagesCarouselGridItemWrapper
      as={motion.div}
      variants={fadeGridImgFromLeft}
    >
      <div ref={sliderEl} className="swiper-container">
        <div className="swiper-wrapper">
          {images?.map((item, index) => (
            <ImagesCarouselImage key={index} image={item} />
          ))}
        </div>
      </div>

      <StyledSwiperBullets ref={sliderPaginationEl} />
    </StyledImagesCarouselGridItemWrapper>
  );
};
