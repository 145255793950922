import styled from "styled-components";
import { Rem, Colors, BP } from "../../commons/Theme";

export const StyledTechCarouselContainer = styled.div`
`;

export const StyledTechCarouselItem = styled.div`
  max-height: 400px;

  @media (${BP.ipad}) {
    max-height: 632px;
  }

  overflow: hidden;
  position: relative;
  border-radius: 20px;
`;

export const StyledImageWrapper= styled.div`
  height: 300px;
  border-radius: 20px;
  @media (${BP.ipad}) {
    height: 100%;
    max-height: 600px;
  }
`;

export const StyledSwiperBullets = styled.div`
  text-align: center;
  padding-top: ${Rem(5)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(15)}
  }

  .swiper-pagination-bullet {
    width: ${Rem(10)};
    height: ${Rem(10)};
    margin: 0 ${Rem(8)} !important;
    border-radius: ${Rem(10)};
    background-color: ${Colors.grayDark};

    @media (${BP.ipad}) {
      width: ${Rem(16)};
      height: ${Rem(16)};
    }
  }

  .swiper-pagination-bullet-active {
    background-color: ${Colors.yellow};
  }
`;
