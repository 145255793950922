import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledImagesCarouselGridItemWrapper = styled(motion.div)`
  .swiper-pagination-bullet {
    background-color: #ffd579;
  }
`;

export const StyledImageCarouselGridItemContainer = styled.div`
  max-width: 100%;
`;
