/* eslint-disable indent */
import { Button } from "@3beehivetech/components";
// import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import {
  StyledItemContainer,
  StyledItemTitle,
  StyledItemDescription,
  StyledLineGridLeft,
  StyledLineGridRight,
} from "./style";
import {
  staggerChildren,
  fadeGridImgFromLeft,
  fadeGridImgFromRight,
  fadeLineFromRight,
  fadeLineFromLeft,
} from "../../animations";
import { motion, useAnimation } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import { DataContext } from "../../../../contexts/DataContext";
import ImagesCarouselGridItem from "../../ImagesCarouselGridItem/ImagesCarouselGridItem";

export default ({ 
  content, 
  isDarkBackground, 
  onCtaClick, 
  direction, 
  line,
}) => {
  const { pageImages } = useContext(DataContext);
  const getImageData = (image) => pageImages.find((e) => e.path === image);

  const [ref, inView] = useInView({ threshold: 0.1 });
  const startAnimate = useAnimation();

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (inView) {
      // setVisible(true)
      startAnimate.start("onscreen");
    } else {
      // setVisible(false)
    }
  }, [inView]);

  const handleActiveIndex = (slideItem) => {
    setActiveIndex(slideItem.activeIndex);
  };

  return (
    <StyledItemContainer
      ref={ref}
      initial="offscreen"
      animate={startAnimate}
      viewport={{ once: false, amount: 0.8 }}
      variants={staggerChildren}
      isDarkBackground={isDarkBackground}
    >
      {direction === "left" && line && (
        <StyledLineGridLeft variants={fadeLineFromLeft} />
      )}
      {direction === "right" && line && (
        <StyledLineGridRight variants={fadeLineFromRight} />
      )}
      {content?.map((contentItem) => {
        switch (contentItem.type) {
          case "title":
            return (
              <StyledItemTitle variants={direction === "left" ?fadeGridImgFromLeft : fadeGridImgFromRight}>
                {contentItem.data}
              </StyledItemTitle>
            );
          case "image":
            // eslint-disable-next-line no-case-declarations
            const imageData = getImageData(contentItem.image);

            return (
              <motion.div variants={direction === "left" ?fadeGridImgFromLeft : fadeGridImgFromRight}>
                <GatsbyImage image={getImage(imageData)} alt={imageData.alt} />
              </motion.div>
            );
          case "description":
            return (
              <motion.div variants={direction === "left" ? fadeGridImgFromLeft : fadeGridImgFromRight}>
                <StyledItemDescription
                  dangerouslySetInnerHTML={{ __html: contentItem.data }}
                />
              </motion.div>

            );
          // carousel components
          case "titles":
            return (
              <StyledItemTitle variants={direction === "left" ? fadeGridImgFromLeft : fadeGridImgFromRight}>
                {contentItem.data[activeIndex]}
              </StyledItemTitle>
            );
          case "carousel":
            return (
              <ImagesCarouselGridItem
                variants={direction === "left" ? fadeGridImgFromLeft : fadeGridImgFromRight}
                images={contentItem.images}
                onSlideChange={handleActiveIndex}
              />
            );
          case "descriptions":
            return (
              <StyledItemDescription
                dangerouslySetInnerHTML={{
                  __html: contentItem.data[activeIndex],
                }}
                variants={direction === "left" ? fadeGridImgFromLeft : fadeGridImgFromRight}
                isCarousel
              />
            );

          case "cta":
            return <Button variants={direction === "left" ?fadeGridImgFromLeft : fadeGridImgFromRight} onClick={onCtaClick}>{contentItem.label}</Button>;
        }
      })}
    </StyledItemContainer>
  );
};
